/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import classNames from 'classnames';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Slice from '../components/slice';
import BottomArrowUp from '../components/bottom-arrow-up-mobile';

export const query = graphql`
    query($id: String!) {
        prismic {
            product(lang: "en-us", uid: $id) {
                title
                summary
                tags {
                    tag {
                        ... on PRISMIC_Tag {
                            name
                            _meta {
                                uid
                            }
                        }
                    }
                }
                body {
                    ... on PRISMIC_ProductBodyVideo {
                        type
                        label
                        primary {
                          video_link
                        }
                      }
                    ... on PRISMIC_ProductBodyText {
                        type
                        label
                        primary {
                            text
                            custom_text_class
                        }
                    }
                    ... on PRISMIC_ProductBodyHero_image {
                        type
                        label
                        primary {
                            hero_image
                            max_width
                            no_margins
                            top_image_on_mobile
                            special_height_mobile
                            no_spacing_mobile
                            fixed_height
                            width
                        }
                    }
                    ... on PRISMIC_ProductBodyImage___text {
                        type
                        label
                        primary {
                            image_side
                            side_image
                            side_text
                            text_over_image
                            no_margins_section
                            section_background_color
                            bottom_section
                            section_link
                            custom_section_class
                        }
                    }
                    ... on PRISMIC_ProductBodyTeam {
                        type
                        label
                        fields {
                            first_and_lastname
                            position
                            description
                            email
                            linkedin
                            portrait
                            profile_orientation
                        }
                    }
                    ... on PRISMIC_ProductBodyGrid {
                        type
                        label
                        fields {
                          background_color
                          color
                          text
                        }
                    }
                    ... on PRISMIC_ProductBodyDownload_link {
                        type
                        primary {
                          downloadText: text
                          file {
                            ... on PRISMIC__FileLink {
                              _linkType
                              name
                              url
                              size
                            }
                          }
                        }
                      }
                    ... on PRISMIC_ProductBodyRelationship {
                        type
                        label
                        primary {
                            section {
                                ... on PRISMIC_Section {
                                    background_color
                                    text_color
                                    _linkType
                                    body {
                                        ... on PRISMIC_SectionBodyImage {
                                            type
                                            label
                                            primary {
                                                hero_image
                                                max_width
                                                no_margins
                                                fixed_height
                                            }
                                        }
                                        ... on PRISMIC_SectionBodyImage___text {
                                            type
                                            label
                                            primary {
                                                image_side
                                                side_image
                                                side_text
                                                vertical_spacing
                                                custom_section_class
                                            }
                                        }
                                        ... on PRISMIC_SectionBodyText {
                                            type
                                            label
                                            primary {
                                                text
                                                custom_text_class
                                            }
                                        }
                                        ... on PRISMIC_SectionBodyInfo_bubble {
                                            type
                                            label
                                           
                                            primary {
                                                max
                                                coupled
                                                custom_bubble_class
                                            }
                                            fields {
                                                background_color
                                                bubble_size
                                                text_above
                                                text
                                                text_below
                                                text_color
                                                fixed_width
                                            }
                                        }
                                        ... on PRISMIC_SectionBodyImage_collage {
                                            type
                                            fields {
                                              image
                                            }
                                            primary {
                                              style
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on PRISMIC_ProductBodyInfo_bubble {
                        type
                        label
                        primary {
                            max
                        }
                        fields {
                            background_color
                            bubble_diameter
                            text
                            text_above
                            text_below
                            text_color
                        }
                    }
                    ... on PRISMIC_ProductBodyVideo {
                        type
                        label
                        primary {
                            thumbnail
                            video_link
                        }
                    }
                    ... on PRISMIC_ProductBodyMap {
                        type
                        label
                        primary {
                            map_location
                            static_image
                        }
                    }
                    ... on PRISMIC_ProductBodyNext_item_cta {
                        type
                        label
                        primary {
                          text
                          background_image
                          cta_text
                          next_item {
                            ... on PRISMIC_Product {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                    }
                    ... on PRISMIC_ProductBodyArticle {
                        type
                        label
                        fields {
                          article_image
                          article_link {
                            ... on PRISMIC__ExternalLink {
                              url
                            }
                            ... on PRISMIC__FileLink {
                              url
                            }
                            ... on PRISMIC__ImageLink {
                              url
                            }
                          }
                          article_summary
                          article_title
                          publication
                          publish_date
                          publisher
                          read_length
                        }
                    }
                    ... on PRISMIC_ProductBodyDotted_border {
                        type
                        label
                        primary {
                            hide_desktop
                        }
                    }
                    ... on PRISMIC_ProductBodyCustom_cta {
                        type
                        label
                        primary {
                            placeholder
                        }
                    }
                    ... on PRISMIC_ProductBodyResponsive_image {
                        type
                        label
                        primary {
                          relationship {
                            ... on PRISMIC_Responsive_image {
                              images {
                                full_width
                                image
                                max_height
                                responsive_width
                                image_position
                                image_size
                              }
                            }
                          }
                        }
                    }
                }
            }
        }
    }
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    ${ props => props.mobilePadding && 'padding-top: 95px;'};
    
    ${props => props.isinsight && `
        div:nth-child(2) {
            margin-top: 0 ;
            margin-bottom: auto;
        }
    `}
    
    ${props => props.isContactPage && `
        .bigger {
            max-width: 275px;
        }        
    `};
    
    @media screen and (max-width: 575px) {
        ${ props => props.isbook && `
            .grid-square:before {
                padding-top: 380px;
            }
        `}
    }
    
    @media screen and (min-width: 575px) {
        ${props => props.isContactPage && `
            .bigger {
                max-width: 430px;
            }
        `};
    }
    
     @media screen and (max-width: 720px) {
         &.aarp-livability-index {
              .postal-code-headline {
                  margin-bottom: 0;
              }
              .address-city-zip-text-image {
                  margin-bottom: 0;
              }
              .first-arrow-text-image, .second-arrow-text-image, .last-arrow-text-image {
                margin: -10px auto 10px;
                width: 75%;
              }
              .first-bubble-side-text {
                margin-bottom: 0;
              }
              .first-bubble-text-image {
                  margin-bottom: 0;
                  img {
                      max-width: 170px;
                      @media screen and (min-width: 575px) {
                          max-width: 250px;
                      }
                  }
              }
              .second-arrow-text-image, .last-arrow-text-image {
                  margin-top: -40px;
              }
              .cercles-image-text-image, .baloons {
                  img {
                      max-width: 90%;
                      @media screen and (min-width: 575px) {
                         max-width: 80%;
                      }
                  }
              }
         }
     }

    @media screen and (min-width: 720px) {
        ${ props => props.mobilePadding && 'padding-top: 75px;'};
        ${ props => props.isContactPage && `
            div.link-with-svg:first-child {
                margin-top: -36px;
            }
            .bigger {
                max-width: 440px;
            }
        `}
        &.goodlife {
            .goodlife-text-image.Left {
                margin-top: -130px;
                .image-text-side-image img {
                    margin: 0;
                }
            }
        }
        &.milo {
            .milo-text-image.Left.phone-section {
                margin-top: -110px;
                z-index: 2;
                .image-text-side-image img {
                    margin: 0;
                }
            }
            .milo-text-image.Right {
                z-index: 3;
            }
        }
    }
    @media screen and (min-width: 720px) and (max-width: 1024px) {
        ${props => props.isinsight && `
            h1 {
                margin-bottom: 8px!important;
            }
            .link-with-svg {
                margin-top: -20px;
            }
        `}
    }
    
    @media screen and (min-width: 1024px) {
        ${ props => props.isbook && `{
            > div:first-child {
                p {
                    font-size: 16px;
                    line-height: 32px;
                }
                .link-with-svg {
                    margin-top: -10px;
                }
            }
        }`};
        
        ${ props => props.isContactPage && `
            > img:first-child {
                max-height: 400px;
            }
        ` }
        
        &.aarp-livability-index {
            // special case for aarp livability index .. check if new slices appear
            .image-text-side-image {
                width: 68%;
            }
            .image-text-side-text {
                width: 32%;
            }
        }
        &.goodlife {
            .goodlife-text-image.Left {
                margin-top: -150px;
                .image-text-side-image img {
                    margin: 0;
                }
            }
            
        }
        &.milo {
            .milo-text-image.Left.phone-section {
                margin-top: -150px;
                .image-text-side-image img {
                    margin: 0;
                }
            } 
        }
    }
    
    @media screen and (min-width: 1440px) {
        ${ props => props.mobilePadding && `{
            > div:first-child {
                h2 {
                    font-size: 45px;
                }
                p {
                    font-size: 24px;
                    line-height: 48px;
                }
            }
        }`};
        
        ${ props => props.isContactPage && `
            > img:first-child {
                max-height: 500px;
            }
             div.link-with-svg:first-child {
                margin-top: -70px;
            }
        `}
        &.goodlife {
            .goodlife-text-image.Left {
                margin-top: -240px;
                .image-text-side-text {
                    margin-top: 170px;
                }
            }
            .goodlife-text-image.Right .image-text-side-text {
                margin-top: -30px;
            }
            .goodlife-side-text {
                 max-width: 477px;
            }
        }
        &.milo {
            .milo-text-image.Left.phone-section {
                margin-top: -220px;
            } 
        }
    }
`;
const OnlyMobile = styled.div`
    & > * {
        display: block !important;
        @media screen and (min-width: 720px) {
            display: none !important;
        }
        height: 250px;
        @media screen and (min-width: 575px) {
            height: 350px;
        }
        @media screen and (min-width: 720px) {
            height: 350px;
        }
        margin: 0;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-direction: column;
    padding:  ${ props => !props.mobilePadding &&
        `25px ${props.theme.paddings['mobile']} 0 ${props.theme.paddings['mobile']};`};
    background-color: ${props => props.theme.white};
    z-index: 2;
    @media screen and (min-width: 575px) {
        padding: ${ props => props.mobilePadding ? '0' :
        `40px ${props.theme.paddings['575']} 10px ${props.theme.paddings['575']};`};
    }
    @media screen and (min-width: 720px) {
        padding: ${ props => props.mobilePadding ? '0' :
        `40px ${props.theme.paddings['720']} 10px ${props.theme.paddings['720']};`};
    }
    @media screen and (min-width: 1024px) {
        padding: ${ props => props.mobilePadding ? '0' :
        `48px ${props.theme.paddings['1024']} 10px ${props.theme.paddings['1024']};`};
    }
    
    @media screen and (min-width: 1440px) {
        padding: ${ props => props.mobilePadding ? '0' :
        `86px ${props.theme.paddings['1440']} 10px ${props.theme.paddings['1440']};`};
    }
    
    &:empty {
        padding: 0;
    }  
`;

const TitleContainer = styled.div`
    display: flex;
    margin-top: 0;
`;

const Title = styled.h1`   
    margin-bottom: 20px;
    width: 50%;
    @media screen and (min-width: 720px) {
        width: 70%;
    }
    @media screen and (min-width: 1024px) {
        margin-bottom: 20px;
    }
`;

const TagSection = styled.div`
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.lightGrey};
    width: 100%;
    text-align: right;
    
    @media screen and (min-width: 720px) {
       text-align: left;
       width: 50%; 
    }
`;

const TagList = styled.div`
    color: #8c8c8c;
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 auto;
    
    @media screen and (min-width: 720px) {
        margin-left: 40px;
    }

    @media screen and (min-width: 1024px) {
        margin-left: 50px;
    }
`;

const Menu = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    ${props => props.first && 'margin-bottom: 7px;'};

    @media screen and (min-width: 720px) {
        ${props => props.first && 'margin-bottom: 0;'};
    }
`;

const MenuItem = styled(Link)`
    color: ${props => props.theme.main};
    text-transform: uppercase;
    margin-right: 45px;
    margin-left: 0;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.main};
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    outline: none;
    text-decoration: none;
    @media screen and (min-width: 575px) {
        margin-right: 30px;
        font-size: 16px;
        line-height: 20px;
    }
    @media screen and (min-width: 720px) {
        margin-right: 35px;
        font-size: 10px;
        line-height: 13px;
    }
    @media screen and (min-width: 1024px) {
        margin-right: 40px;
        font-size: 16px;
        line-height: 20px;
    }
    @media screen and (min-width: 1440px) {
        margin-right: 80px;
        font-size: 20px;
        line-height: 25px;
    }
`;

const FlexContainer = styled.section`
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    flex-direction: column;
    height: 95px;
    padding-left: ${props => props.theme.paddings['mobile']};
    position: fixed;
    top: 0;
    left: 0;
    transition: all 300ms ease;
    transform: translateY(100px);
    background-color: ${props => props.theme.white};
    z-index: 2;
    width: 100%;

    &.about-menu__open {
        transform: translateY(95px);
        transition: transform 300ms ease-in;
        
        @media screen and (min-width: 720px) {
            transform: none;
        }
    }
    &.about-menu__closed {
        transform: translateY(0);
        transition: transform 300ms ease-out;
    }

    @media screen and (max-width: 574px) {
        justify-content: center;
        align-items: flex-start;
    }
    @media screen and (min-width: 575px) {
        flex-direction: row;
        padding: 34px ${props => props.theme.paddings['575']};
    }
    @media screen and (min-width: 720px) {
        align-items: center;
        border-bottom: none;
        flex-direction: row;
        height: 64px;
        left: 64px;
        padding: 35px ${props => props.theme.paddings['720']};
        transform: none;
    }

    @media screen and (min-width: 1024px) {
        height: 90px;
        left: 90px;
        padding: 35px ${props => props.theme.paddings['1024']};

    }

    @media screen and (min-width: 1440px) {
        font-size: 20px;
        height: 120px;
        left: 120px;
        padding:  0 310px 0 ${props => props.theme.paddings['1440']};
    }
`;

const Product = ({ data, pageContext }) => {
    // Required check for no data being returned
    const doc = data.prismic.product;
    if (!doc) {
        return null;
    }
    const { title, tags, summary } = doc;
    const aboutMenuRef = useRef();
    const contentRef = useRef();
    const isAboutPage = pageContext.pageName === 'About';
    const isBookPage = pageContext.id === 'our-book';
    const isInsightsPage = pageContext.id === 'insights';
    const isContactPage = pageContext.pageName === 'Contact';
    const isMediaPage = pageContext.id === 'media';
    const filteredTags = tags.filter(item => {
        if (item.tag) {
            return item.tag._meta.uid && item.tag.name;
        } else {
            return false;
        }
    });

    const hideOnScroll = useRef(true);
    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y;
        if (aboutMenuRef.current && contentRef.current) {
            if (currPos.y > -100) {
                aboutMenuRef.current.classList.add('about-menu__open');
                aboutMenuRef.current.classList.remove('about-menu__closed');
            } else {
                if (isShow !== hideOnScroll) {
                    if (isShow) {
                        aboutMenuRef.current.classList.add('about-menu__open');
                        aboutMenuRef.current.classList.remove('about-menu__closed');
                    } else {
                        aboutMenuRef.current.classList.add('about-menu__closed');
                        aboutMenuRef.current.classList.remove('about-menu__open');
                    }
                    hideOnScroll.current = isShow;
                }
            }
        }
    }, [hideOnScroll]);

    const renderAboutMenu = () => {
        const renderMenuItems = (list) => {
            if (list) {
                return list.map((product, index) => {
                    return <MenuItem activeStyle={{ color: '#212121' }} tabIndex={0} aria-label={`link to ${product.title} page`}
                        key={index} to={`/about/${product.uid}`}>{product.title}</MenuItem>;
                });
            } else {
                return <></>;
            }

        };
        const copyList = pageContext.productList ? [...pageContext.productList] : [];
        const halfPoint = Math.floor(copyList.length / 2);
        const basis = halfPoint * 100 / copyList.length;
        return <FlexContainer ref={aboutMenuRef}>
            <Menu basis={basis} first>
                {renderMenuItems(copyList.splice(0, halfPoint))}
            </Menu>
            <Menu basis={100 - basis}>
                {renderMenuItems(copyList)}
            </Menu>
        </FlexContainer>;
    };

    const renderTags = (title, filteredTags) => {
        return <TitleContainer>
            <Title>{title}</Title>
            <TagSection className="tags">
                {
                    filteredTags.length > 0 && <>
                        <TagList>
                            {filteredTags.map((tag, t) => (
                                <div key={t}>{tag.tag && tag.tag.name}</div>
                            ))}
                        </TagList>
                    </>
                }
            </TagSection>
        </TitleContainer>;
    };
    const top_image = doc.body ? doc.body.find((value => {
        if (value.primary) {
            return value.primary.top_image_on_mobile === 'True';
        } else {
            return false;
        }
    })) : null;
    const className = classNames({
        'product': !isMediaPage,
        [pageContext.id]: pageContext.id,

    });
    const pageUrl = typeof window !== 'undefined' && window.location.href;
    let seoDescription = '';
    summary && summary.map(item => seoDescription += item.text);

    const handleSlices = (slices) => {
        if (!slices) {
            return null;
        }
        return slices.map((slice, index) => {
            return <Slice key={index} data={slice}/>;
        });
    };
    return (
        <Layout>
            <SEO title={title} canonical={pageUrl} description={seoDescription}/>

            <OnlyMobile>
                <Slice data={top_image} />
            </OnlyMobile>

            <HeaderSection mobilePadding={isAboutPage}>
                {isAboutPage ? (renderAboutMenu()) : isContactPage ? <></> : renderTags(title, filteredTags)}
            </HeaderSection>

            <Section className={className}
                mobilePadding={isAboutPage} ref={contentRef} isbook={isBookPage}
                isinsight={isInsightsPage} isContactPage={isContactPage}>
                { handleSlices(doc.body) }
            </Section>

            {!isInsightsPage && <BottomArrowUp media={isMediaPage ? isMediaPage : undefined} />}
        </Layout>
    );
};

Product.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
    theme: PropTypes.object,
    first: PropTypes.any,
};

export default Product;
